@import "../../styles/variables";

@keyframes fade {
  to {
    opacity: 1;
  }
}

.home {
  .portfolio {
    .portfolio-links {
      .portfolio-link {
        .bullet {
          border: 2px solid white;
        }
        &.active {
          .bullet {
            background-color: white;
          }
        }
      }
    }
  }
}
.portfolio {
  color: white;
  .scroll-hint {
    position: fixed;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
    animation: fade 500ms infinite alternate ease-in-out;
    animation-iteration-count: 8;
    opacity: 0;
  }
  .portfolio-links {
    @media (max-width: $screen-xs-max) {
      display: none;
    }
    position: fixed;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    width: 10px;
    .portfolio-link {
      position: relative;
      width: 20px;
      height: 20px;
      display: block;
      padding: 5px;
      .bullet {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid black;
        transition: all 400ms ease-in-out;
      }
      &.active {
        .bullet {
          background-color: black;
        }
      }
    }
    .portfolio-link-tooltip {
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      padding: 5px 10px;
      white-space: nowrap;
      font-family: "Chakra Petch", sans-serif;
      right: 30px;
      top: -5px;
      color: white;
      &:after {
        position: absolute;
        display: block;
        content: " ";
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid rgba(0, 0, 0, 0.5);
        right: -10px;
        top: 5px;
      }
    }
  }
  .portfolio-items {
    height: 100vh;
    overflow: hidden;
    // padding: 200px 0;
    // overflow-y: scroll;
    // scroll-snap-type: y mandatory;
    .portfolio-item {
      position: relative;
      // scroll-snap-align: start;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $screen-xs-max) {
        display: block;
        height: auto;
        padding: 20px;
        min-height: 100vh;
      }
      .title-box {
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        @media (max-width: $screen-xs-max) {
          position: relative;
          top: auto;
          left: auto;
          transform: none;
          margin-bottom: 20px;
        }
      }
      .cover-image {
        max-height: 150px;
        margin-bottom: 20px;
        @media (max-width: $screen-xs-max) {
          margin: 0;
        }
      }
      .content {
        position: relative;
        max-width: 1200px;
        flex: 1;
        display: flex;
        align-items: center;
        @media (max-width: $screen-xs-max) {
          display: block;
        }
      }
      .text,
      .images {
        // display: flex;
        flex: 1;
        margin: 0 20px;
        @media (max-width: $screen-xs-max) {
          font-size: 14px;
        }
      }
      .text {
        height: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        @media (max-width: $screen-xs-max) {
          display: block;
          height: auto;
          margin: 0;
        }
      }
      .tags {
        line-height: 30px;
        .tag {
          &.black {
            background-color: black;
            .hole {
              background-color: black;
            }
          }
          .hole {
            position: relative;
            left: -7px;
            display: inline-block;
            height: 14px;
            width: 14px;
            transform-origin: center;
            transform: rotate(-45deg);
            background-color: white;
            text-align: center;
            line-height: 15px;
          }
          font-family: "Chakra Petch", sans-serif;
          height: 20px;
          line-height: 20px;
          position: relative;
          background-color: white;
          display: inline-block;
          padding-right: 5px;
          font-size: 14px;
        }
      }
      .images {
        position: relative;
        border: 10px solid white;
        box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.5);
        overflow: hidden;
        max-height: 33vh;
        @media (max-width: $screen-xs-max) {
          max-height: 300px;
          margin: 0;
          margin-bottom: 20px;
        }
        .image {
          display: block;
          max-width: 100%;
          cursor: pointer;
        }
        .next-button,
        .prev-button {
          position: absolute;
          top: 50%;
          background: rgba(0, 0, 0, 0.4);
          padding: 10px;
          border-radius: 4px;
          display: block;
          border: none;
          height: 40px;
          width: 40px;
          outline: 0;
          z-index: 100;
          font-weight: bold;
          line-height: 40px;
          padding: 0;
          transform: translateY(-50%);
          color: white;
        }
        .next-button {
          &:after {
            content: " ";
            display: block;
            position: absolute;
            top: -2px;
            right: 5px;
            width: 100%;
            height: 100%;
            border-top: 4px solid white;
            border-right: 4px solid white;
            transform-origin: center;
            transform: scale(0.5) rotate(45deg);
          }
          right: 0;
        }
        .prev-button {
          left: 0;
          &:after {
            content: " ";
            display: block;
            position: absolute;
            top: -2px;
            left: 5px;
            width: 100%;
            height: 100%;
            border-top: 4px solid white;
            border-left: 4px solid white;
            transform-origin: center;
            transform: scale(0.5) rotate(-45deg);
          }
        }
      }
      &:nth-child(even) {
        .content {
          flex-direction: row-reverse;
        }
        .tags {
          .tag {
            margin-right: 20px;
            @media (max-width: $screen-xs-max) {
              margin-right: 0;
              margin-left: 20px;
            }
          }
        }
      }
      &:nth-child(odd) {
        .content {
          flex-direction: row;
          text-align: right;
          @media (max-width: $screen-xs-max) {
            text-align: left;
            margin-right: 0;
          }
          ul {
            li {
              list-style-position: inside;
            }
          }
        }
        .tags {
          .tag {
            margin-left: 20px;
            @media (max-width: $screen-xs-max) {
              margin-left: 20px;
              // margin-right: 20px;
            }
          }
        }
      }
    }
  }
}
