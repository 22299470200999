.lang {
  position: absolute;
  top: 40px;
  left: 40px;
  a {
    &:after {
      content: " | ";
    }
    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
}
