@import "../variables";

$transparent: rgba($link-color, 0);
$solid: rgba(gray, 1);

@keyframes cursor {
  from {
    background-color: $transparent;
  }
  to {
    background-color: $solid;
  }
}

@mixin active {
  // color: $link-hover-color;
  text-decoration: none;
  line-height: 1;
  &:after {
    content: " ";
    width: 0.5rem;
    height: 1rem;
    animation: cursor 300ms ease-in-out alternate infinite;
    display: inline-block;
    vertical-align: bottom;
  }
}
