@import "../../styles/variables";

.sidebar {
  pointer-events: none;
  transition: opacity 400ms linear;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-family: "Chakra Petch", sans-serif;
  color: white;
  z-index: 1000;
  a {
    color: $gray-light;
    text-decoration: none !important;
    margin-bottom: 20px;
    &.active,
    &:hover {
      color: white;
    }
  }
  &.backdrop {
    background-color: rgba(0, 0, 0, 0.4);
  }
  &.active {
    opacity: 1;
    &.backdrop {
      pointer-events: all;
    }
  }
  .content {
    pointer-events: all;
    position: absolute;
    top: 0;
    background-color: #373a47;
    height: 100vh;
    padding: 40px;
    padding-top: 80px;
    transition: transform 400ms ease-in-out;
    overflow: hidden;
    user-select: none;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 200px;
    &.right {
      transform: translateX(100%);
      right: 0;
    }
    &.left {
      transform: translateX(-100%);
      left: 0;
    }
    &.active {
      transform: translateX(0);
    }
    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }
}
