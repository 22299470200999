@import "../../styles/variables";

.about-crop {
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100vw;
  .scroll-tip {
    position: fixed;
    top: 40px;
    left: 50%;
    color: white;
    opacity: 0;
    animation: fade 500ms linear alternate;
    animation-iteration-count: 8;
    transform: translateX(-50%);
    text-align: center;
    img {
      margin: 20px auto;
      width: 40px;
      display: block;
    }
  }
}
.about-wrapper {
  background: black;
  height: 100%;
  width: 100%;
}
.about-container {
  background-image: url("../../static/images/about/stars.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-color: black;
  background-size: cover;
  position: relative;
  transform-origin: center;
  position: relative;
  width: 100%;
  height: 100%;
  .about {
    position: absolute;
    width: 100%;
    height: 2000%;
    bottom: 0;
    left: 0;
    font-size: 3rem;
    font-weight: 500;
    text-align: justify;
    transform-origin: 50% 100%;
    transform: perspective(300px) rotateX(25deg);
    color: rgb(229, 177, 58);
    @media (max-height: 600px) {
      font-size: 1.5rem;
      transform: perspective(300px) rotateX(40deg);
    }
    .about-content {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      transform: translate(-50%, 100%);
      max-width: 1000px;
      min-width: 500px;
      .title {
        text-align: center;
        font-size: 4rem;
        margin-bottom: 4rem;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        @media (max-width: $screen-xs-max) {
          font-size: 2rem;
        }
      }
      p {
        margin-bottom: 3rem;
      }
    }
  }
}
.introduction {
  position: absolute;
  width: 600px;
  font-size: 3rem;
  font-weight: 500;
  color: rgb(75, 213, 238);
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: $screen-xs-max) {
    font-size: 2rem;
    text-align: center;
    width: 90vw;
  }
}
.buttons {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.mute-button,
.pause-button {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: none;
  border: none;
  padding: none;
  outline: none;
  padding: 0;
  margin-left: 5px;
  &:hover {
    color: #ccc;
  }
}
.start-button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 800;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
