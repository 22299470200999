@import "../../styles/variables";
@import "../../styles/mixins/active";

.splash {
  height: 100vh;
  background-image: url("../../static/images/home/splash.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  .hero-unit {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 600px;
    height: 360px;
    transform: translate(-50%, -50%);
    padding: 40px;
    text-align: center;
    h1 {
      margin-bottom: 1rem;
      text-transform: uppercase;
      border-bottom: 1px solid white;
    }
    p {
      font-size: 1rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    @media (max-width: 600px) {
      width: 100%;
      height: 400px;
      h1 {
        font-size: 30px;
      }
    }
  }
  .payoffs {
    text-align: left;
    font-family: "Chakra Petch", sans-serif;
    font-size: 25px;
    div > :last-child {
      @include active;
      &:after {
        height: 30px;
        width: 18px;
        @media (max-width: $screen-xs-max) {
          height: 24px;
          width: 12px;
        }
      }
    }
    @media (max-width: $screen-xs-max) {
      font-size: 20px;
    }
  }
}
