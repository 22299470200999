@import "../../styles/variables";

.contact {
  display: flex;
  .left,
  .right {
    height: 100vh;
    padding: 40px;
    display: flex;
    align-items: center;
    .content {
      width: 100%;
    }
  }
  .left {
    width: 60%;
    position: relative;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .details {
      top: 40px;
      left: 40px;
      position: absolute;
      z-index: 10000;
      background: rgba(255, 255, 255, 0.5);
    }
  }
  .right {
    width: 40%;
    background-color: #373a47;
    color: $gray-lighter;
  }
  .error {
    background: darkred;
    color: white;
    font-weight: 500;
    padding: 0.5rem;
    position: relative;
    top: 0.5rem;
    &:after {
      bottom: 100%;
      left: 1.5rem;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: transparent;
      border-bottom-color: darkred;
      border-width: 1rem;
      margin-left: -1rem;
    }
  }
  .base-error {
    background-color: darkred;
    font-weight: bold;
    color: white;
    padding: 0.5rem;
    margin-bottom: 10px;
  }
  .success {
    background: green;
    font-weight: bold;
    color: white;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .form-group {
    margin-bottom: 1rem;
  }
  .form-control {
    width: 100%;
    display: block;
    background-color: transparent;
    border: 2px solid $gray-lighter;
    color: $gray-lighter;
    font-size: 16px;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    box-shadow: none !important;
    outline: none !important;
    border-radius: 0;
    padding: 5px;
    &:focus {
      border: 2px solid white;
    }
  }
  label {
    color: $gray-lighter;
    font-family: Roboto;
    font-weight: normal;
    font-weight: 500;
  }
  .btn {
    outline: 0 !important;
    border-radius: 0;
    width: 100%;
    padding: 1rem;
    background-color: #50a6f6;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
  }
}
