.city {
  .load-scene-button,
  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
  }
  .load-scene-button {
    color: black;
  }

  .blocker {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
  }
  .instructions {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    font-size: 40px;
    display: block;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
}
