@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
$font-size-base: 16px;
$font-size-h1: 2.6rem;
$font-size-h2: 2.15rem;
$font-size-h3: 1.7rem;
$font-size-h4: 1.25rem;
$font-size-h4: 1rem;
$font-size-h6: 0.85rem;
//$font-size-large: 1.25rem;
//$font-size-small: 0.85rem;

$link-color: #7D1B58;
$link-hover-color: darken($link-color, 20%);
