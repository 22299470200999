@import "../../styles/variables";

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  .popup-content {
    position: relative;
    min-width: 60vw;
    min-height: 60vh;
    max-height: 80vh;
    max-width: 80vw;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      display: block;
      box-shadow: 0 0 100px black;
    }
  }
  .popup-close,
  .next,
  .prev {
    display: block;
    position: fixed;
    width: 60px;
    height: 60px;
    background: none;
    padding: 0;
    border: none;
    margin: 0;
    overflow: visible;
    outline: 0;
  }
  .popup-close {
    top: 40px;
    right: 40px;
    &:before,
    &:after {
      content: " ";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 0;
      border-top: 2px solid white;
      transform-origin: top center;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  .prev,
  .next {
    top: 50%;

    @media (max-width: $screen-xs-max) {
      top: auto;
      bottom: 40px;
    }
    // transform: translateY(-50%);
  }
  .prev {
    border-top: 2px solid white;
    border-left: 2px solid white;
    transform-origin: top left;
    transform: rotate(-45deg);
    left: 40px;
  }
  .next {
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform-origin: top right;
    transform: rotate(45deg);
    right: 40px;
  }
}
