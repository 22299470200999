@import "../../styles/variables";

.experiments {
  user-select: none;
  height: 100vh;
  .menu-button {
    position: fixed;
    top: 40px;
    left: 40px;
    z-index: 1001;
    width: 40px;
    height: 35px;
    background-color: transparent;
    padding: 0;
    outline: 0;
    border-radius: 0;
    border: 0;
    transition: transform 200ms ease-in-out;
    &.active {
      transform: rotate(-90deg);
      .line1 {
        transform: rotate(45deg);
      }
      .line2 {
        opacity: 0;
      }
      .line3 {
        transform: rotate(-45deg);
      }
    }
    .line {
      height: 5px;
      background-color: white;
      transform-origin: left center;
      transition: all 400ms ease-in-out;
    }
    .line2 {
      margin-top: 9px;
      margin-bottom: 9px;
      width: 50%;
    }
  }
  .experiments-navigation {
    padding-top: 40px;
    .experiment-link {
      display: block;
      font-family: "Chakra Petch", sans-serif;
      color: $gray-light;
      text-decoration: none !important;
      &.active,
      &:hover {
        color: white;
      }
    }
  }
  .experiment-details {
    width: 100%;
    height: 100vh;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    vertical-align: top;
    .experiment,
    .category {
      color: white;
      position: relative;
      .title {
        position: absolute;
        bottom: 0;
        right: 0;
        text-transform: uppercase;
        background: black;
        padding: 15px;
        color: white;
        border: 1px solid white;
        font-family: "Chakra Petch", sans-serif;
      }
      height: 100vh;
      width: 100vw;
      display: inline-block;
      vertical-align: top;
    }
    .category {
      width: auto;
    }
    .title-screen {
      position: relative;
      .title-container {
        height: 100%;
        display: flex;
        align-items: center;
      }
      .content {
        width: 100%;
        text-align: center;
      }
    }
  }
  .next,
  .prev {
    display: block;
    position: fixed;
    width: 60px;
    height: 60px;
    background: none;
    padding: 0;
    border: none;
    margin: 0;
    overflow: visible;
    outline: 0;
  }
  .prev,
  .next {
    top: 50%;
    // transform: translateY(-50%);
  }
  .prev {
    border-top: 2px solid white;
    border-left: 2px solid white;
    transform-origin: top left;
    transform: rotate(-45deg);
    left: 40px;
  }
  .next {
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform-origin: top right;
    transform: rotate(45deg);
    right: 40px;
  }
  // .navigation {
  //   .nav-next,
  //   .nav-prev {
  //     position: fixed;
  //     top: 50%;
  //     font-size: 40px;
  //     color: white;
  //     text-decoration: none !important;
  //     background: rgba(0, 0, 0, 0.5);
  //     transition: background-color 300ms linear;
  //     &:hover {
  //       background: rgba(0, 0, 0, 1);
  //     }
  //     display: block;
  //     height: 60px;
  //     width: 60px;
  //     text-align: center;
  //     line-height: 56px;
  //     border-radius: 7px;
  //   }
  //   .nav-next {
  //     right: 20px;
  //   }
  //   .nav-prev {
  //     left: 20px;
  //   }
  // }
}
