@import "reset";

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

.no-scroll {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body {
  font-size: 17px;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 600;
}
h1 {
  font-size: 40px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 25px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
p {
  margin-bottom: 1rem;
}
b {
  font-weight: 500;
}
.page-title {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  text-align: center;
  text-decoration: underline;
}
