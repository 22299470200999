@import "../../styles/variables";

.not-found {
  * {
    overflow: visible;
  }
  overflow: visible;
  height: 100vh;
  width: 100vw;
  perspective: 1000px;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  .content {
    overflow: visible;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .title,
  .subtitle,
  .text {
    > span {
      display: inline-block;
    }
  }
  .explosion {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: -1;
  }
  .title {
    font-size: 60px;
  }
  .subtitle {
    font-size: 40px;
  }
  .button {
    cursor: pointer;
  }
}
