@import "../../styles/mixins/active";
@import "../../styles/variables";

.header {
  font-family: "Abel", sans-serif;
  .open-navigation-link {
    position: fixed;
    top: 40px;
    right: 40px;
    width: 40px;
    height: 34px;
    display: block;
    z-index: 1011;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    transition: transform 200ms ease-in-out;
    transform-origin: center;
    outline: none;
    &.active {
      transform: rotate(180deg);
      .line {
        background-color: white;
      }
      .line1 {
        transform: rotate(45deg);
      }
      .line2 {
        opacity: 0;
      }
      .line3 {
        transform: rotate(-45deg);
      }
    }
    .line {
      height: 5px;
      background-color: #333;
      border-radius: 3px;
      transition: all 400ms ease-in-out;
      transform-origin: left center;
    }
    .line2 {
      margin-top: 9px;
      margin-bottom: 9px;
    }
  }
  .page-header {
    padding: 0;
    margin: 0;
    padding-bottom: 1rem;
  }
}
.home {
  .header {
    background: black;
    margin-bottom: 0;
  }
  .site-title,
  .site-subtitle {
    color: white;
  }
  .site-title {
    a {
      color: white;
      &:hover {
        color: $gray-light;
      }
    }
  }
}
.home {
  .header {
    .open-navigation-link {
      .line {
        background-color: white;
      }
    }
  }
}
