@import "../../styles/variables";
@import "../../styles/mixins/active";

.blog {
  padding: 20px;
  .page-title {
    position: relative;
    top: auto;
    margin-bottom: 40px;
  }
  .content-container {
    position: relative;
    transition: all 400ms ease-in-out;
    padding-right: 0;
    &.sidebar-open {
      transform: translateX(25vw);
      padding-right: 25vw;
      @media (max-width: $screen-xs-max) {
        transform: translateX(100vw);
      }
    }
  }
  .sidebar {
    a {
      margin-bottom: 0;
    }
    .tag {
      font-size: 0.9rem;
      padding-right: 0.5rem;
      display: inline-block;
    }
    .category,
    .tag,
    .timeline-item {
      &.active {
        font-weight: 500;
      }
    }
  }
  .content {
    max-width: 1000px;
    margin: auto;
  }
  .blog-items {
    border-top: 1px solid $gray-lighter;
    padding-top: 1rem;
  }
  .next-button,
  .prev-button {
    margin-bottom: 1rem;
  }
  .blog-navigation {
    padding-top: 40px;
    font-family: "Chakra Petch", sans-serif;
    .filter-link {
      color: $gray-light;
      &.active,
      &:hover {
        color: white;
        text-decoration: none;
      }
    }
    .filters,
    .tags,
    .categories {
      margin-bottom: 40px;
    }
  }
  .blog-item {
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 1rem;
    .name {
      margin: 0;
      margin-bottom: 0.25rem;
    }
    .blog-link {
      display: block;
      text-decoration: none !important;
      margin-bottom: 1rem;
      color: $gray-darker;
      &:hover {
        color: $link-color;
      }
    }
  }
  .sidebar {
    .content {
      width: 400px;
      position: absolute;
      @media (max-width: $screen-xs-max) {
        width: 100%;
      }
    }
    .clear-filters {
      position: absolute;
      top: 40px;
      right: 40px;
    }
  }
  .sidebar-button {
    position: fixed;
    top: 40px;
    left: 40px;
    width: 40px;
    height: 40px;
    background-color: transparent;
    padding: 0;
    line-height: 40px;
    border: none;
    outline: 0;
    transition: 400ms transform ease-in-out;
    z-index: 1001;
    &.active {
      transform: rotate(-135deg);
      .line {
        background-color: white;
        transition: background-color 400ms ease-in-out;
      }
    }
    .line {
      position: absolute;
      top: 16px;
      height: 5px;
      background-color: black;
      transform-origin: center center;
      width: 100%;
    }
    .line1 {
    }
    .line2 {
      transform: rotate(90deg);
    }
  }
  .blog-item-detail {
    overflow: hidden;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background: #eee;
    box-shadow: inset 0 8px 8px -8px #666, inset 0 -8px 8px -8px #666;
    img {
      max-width: 100%;
      display: block;
    }
    .content {
      margin: 1rem;
    }
    code,
    pre {
      border-radius: 0;
      color: #c5c8c6;
      text-shadow: 0 1px rgba(0, 0, 0, 0.3);
      font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier,
        monospace;
      direction: ltr;
      text-align: left;
      white-space: pre;
      word-spacing: normal;
      word-break: normal;
      line-height: 1.5;

      -moz-tab-size: 4;
      -o-tab-size: 4;
      tab-size: 4;

      -webkit-hyphens: none;
      -moz-hyphens: none;
      -ms-hyphens: none;
      hyphens: none;
    }

    /* Code blocks */
    pre {
      padding: 1em;
      margin: 0.5em 0;
      overflow: auto;
      // border-radius: 0.3em;
      margin-bottom: 1rem;
    }

    :not(pre) > code,
    pre {
      background: #1d1f21;
    }

    /* Inline code */
    :not(pre) > code {
      padding: 0.1em;
      // border-radius: 0.3em;
    }

    .token.comment,
    .token.prolog,
    .token.doctype,
    .token.cdata {
      color: #7c7c7c;
    }

    .token.punctuation {
      color: #c5c8c6;
    }

    .namespace {
      opacity: 0.7;
    }

    .token.property,
    .token.keyword,
    .token.tag {
      color: #96cbfe;
    }

    .token.class-name {
      color: #ffffb6;
      text-decoration: underline;
    }

    .token.boolean,
    .token.constant {
      color: #99cc99;
    }

    .token.symbol,
    .token.deleted {
      color: #f92672;
    }

    .token.number {
      color: #ff73fd;
    }

    .token.selector,
    .token.attr-name,
    .token.string,
    .token.char,
    .token.builtin,
    .token.inserted {
      color: #a8ff60;
    }

    .token.variable {
      color: #c6c5fe;
    }

    .token.operator {
      color: #ededed;
    }

    .token.entity {
      color: #ffffb6;
      /* text-decoration: underline; */
    }

    .token.url {
      color: #96cbfe;
    }

    .language-css .token.string,
    .style .token.string {
      color: #87c38a;
    }

    .token.atrule,
    .token.attr-value {
      color: #f9ee98;
    }

    .token.function {
      color: #dad085;
    }

    .token.regex {
      color: #e9c062;
    }

    .token.important {
      color: #fd971f;
    }

    .token.important,
    .token.bold {
      font-weight: bold;
    }
    .token.italic {
      font-style: italic;
    }

    .token.entity {
      cursor: help;
    }
  }
}
