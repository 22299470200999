@import "../../styles/variables";

@keyframes shine {
  0% {
    background-position: -1000px 0;
  }
  20% {
    background-position: 1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skills-container {
  transition: background-color 400ms ease-in-out;
  min-height: 100vh;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.skills {
  transform-style: preserve-3d;
  transform-origin: center;
  .category {
    display: block;
    transform-style: preserve-3d;
    position: relative;
    width: 500px;
    height: 500px;
    transform-origin: center;
    border-radius: 50%;
    user-select: none;
    position: absolute;
    border: 10px solid $gray-lighter;
    .back,
    .front {
      text-decoration: none !important;
      display: block;
      backface-visibility: hidden;
      border-radius: 50%;
      background-color: black;
      color: white;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      box-shadow: 0 0 15px inset rgba(0, 0, 0, 0.4);
      .content {
        flex: 1;
      }
    }
    .front {
      z-index: 2;
      transform: rotateY(0deg);
      &:hover {
        background-color: #333;
      }
      .skill-count {
        font-size: 8rem;
        font-weight: 300;
      }
      .name {
        font-size: 2.5rem;
      }
    }
    .back {
      background-image: url(../../static/images/skills/shine.png);
      background-repeat: no-repeat;
      background-position: -1000px 0;
      z-index: 1;
      transform: rotateY(180deg);
      animation: shine 8s infinite 5s;
      .content {
        text-shadow: 0px -1px 1px rgba(0, 0, 0, 0.5);
        width: 100%;
        .page {
          padding-top: 20px;
          font-size: 17px;
        }
      }
      .skill-list {
        margin: auto;
        width: 60%;
        font-weight: 500;
        text-align: left;
        font-size: 17px;
        line-height: 30px;
        td {
          width: 50%;
          vertical-align: top;
        }
        .level {
          margin-left: 5px;
          margin-top: 5px;
          height: 20px;
          position: relative;
          width: 100%;
          border: 1px solid white;
          box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.5);
          .level-bar {
            width: 0;
            background-color: white;
            height: 18px;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
    .load-more-button {
      position: absolute;
      bottom: 40px;
      transform: translateX(-50%);
      text-shadow: none;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      background: $gray-lighter;
      padding: 10px;
      color: #333;
      font-weight: 500;
      &:active {
        border-top: 1px solid gray;
        border-left: 1px solid gray;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
      }
    }
  }
}
