@import "../../styles/variables";
@import "../../styles/mixins/active";

.main-navigation {
  overflow-y: scroll;
  .navigation-link {
    display: block;
    padding-left: 0;
    text-decoration: none !important;
    position: relative;
    font-size: 20px;
    color: $gray-light;
    transition: color 300ms linear;
    line-height: 1;
    &:hover,
    &.active {
      color: white;
    }
    &:hover {
      @include active;
      &:after {
        height: 20px;
        width: 12px;
      }
    }
  }
  .site-title {
    a {
      color: $gray-light;
      text-decoration: none !important;
      &:hover {
        color: white;
      }
    }
    @media (max-width: $screen-xs-max) {
      font-size: 2rem;
    }
    margin-bottom: 20px;
  }
  .site-subtitle {
    position: relative;
    @include active;
    font-size: 25px;
    margin-bottom: 25px;
    line-height: 1;
    &:after {
      top: 0rem;
      height: 1.7rem;
      width: 1rem;
    }
    @media (max-width: $screen-xs-max) {
      display: block;
      height: 1rem;
      &:after {
        top: 0;
        height: 1.26rem;
        width: 0.7rem;
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 40px;
    left: 40px;
    @media (max-height: 800px) {
      position: relative;
      left: auto;
      bottom: auto;
    }
  }
}
